<template lang="pug">
  section.latest-news
    .latest-news__container.latest-news__container--big
      .latest-news__inner
        .latest-news__top
          h3.latest-news__title.title Новини
          router-link.latest-news__link.link(:to="{ name: 'News' }") Всі новини
        .latest-news__text
          p Будьте завжди в курсі наших останніх подій
        NewsCarousel
</template>

<script>
export default {
  name: 'LatestNews',
  components: {
    NewsCarousel: () => import('@/components/NewsCarousel')
  }
}
</script>
